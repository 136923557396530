import React from "react"

import { navigate } from "gatsby"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { AuthContext } from "../store"

const LoginPage = () => {
  const { isAuthenticated, loginUser } = React.useContext(AuthContext)

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (e) => {
    e.preventDefault()

    loginUser({ email, password })
  }

  return (
    <AuthWrapper title="Login" isLogin>
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="email"
          id="email"
          label="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isBlock
          required
        />
        <Input
          type="password"
          id="email"
          label="email"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          minLength="8"
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Login
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default LoginPage
